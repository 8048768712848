import { css } from '@emotion/react';
import {
  Button,
  color,
  DropZone,
  FormGroup,
  Icon,
  Modal,
  spacing
} from '@uniquegood/realworld-studio-design';
import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { IFormGroupProps } from '@uniquegood/realworld-studio-design/dist/types/organisms/FormGroup/FormGroup';
import { Flex, HideElementStyle, MarginBottom10, TextAlignCenter } from '@/styles';
import { assetOverlayImage } from '../assets';
import { coreApi } from '@/api';
import { makeFormData } from '@/utils';
import { UploadLoadingSpinner } from '@/components/Uploader';
import { useModalState } from '@/hooks';

interface VideoPreviewUploaderProps extends Pick<IFormGroupProps, 'label'> {
  value?: CommonFileModel;
  onChange(value: CommonFileModel | undefined): unknown;
}

export default function VideoPreviewUploader({
  value,
  onChange,
  ...formGroupProps
}: VideoPreviewUploaderProps) {
  const [uploadState, onChangeFile] = useAsyncFn(async (files: File[] | undefined) => {
    if (files?.length === 1) {
      const response = await coreApi.post<UserFileResponseModel>(
        `apps/${appId}/videos/uploadFile`,
        makeFormData({ file: files[0] })
      );

      onChange({
        userFileId: response.data.id,
        fileUrl: response.data.url,
        fileType: 'video'
    });
    }

    return undefined;
  }, []);
  const { modal, openModal, closeModal } = useModalState();

  const { appId } = useParams<AppParam>();

  function notAcceptFiles() {
    openModal({
      size: 'small',
      title: '형식에 맞는 파일을 업로드해주세요',
      cssStyle: TextAlignCenter,
      children: (
        <>
          <Icon
            cssStyle={MarginBottom10}
            icon="times_circle_regular"
            size="100px"
            color={color.interactive_critical_default}
          />
          올바른 파일 형식을 업로드해주세요
        </>
      ),
      primaryAction: {
        content: '확인',
        onAction: closeModal
      }
    });
  }

  function playVideo(video: HTMLVideoElement | null) {
    if (video) {
      video.play();
    }
  }

  return (
    <FormGroup {...formGroupProps}>
      <div css={Flex}>
        <div css={OverlayVideoWrap}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          {!!value && <video src={value.fileUrl} autoPlay loop muted ref={playVideo} />}
          <div css={OverlayVideo} />
        </div>
        {!value &&
          (uploadState.loading ? (
            <UploadLoadingSpinner isMultiple={false} size="medium" ratio="vertical" />
          ) : (
            <DropZone
              accepts={['video/mp4', 'video/webm']}
              onChangeFile={onChangeFile}
              notAcceptFiles={notAcceptFiles}
              size="medium"
              ratio="vertical"
            />
          ))}
        {!!value && (
          <div>
            <video src={value.fileUrl} controls muted css={VideoPreview} />
            <Button
              type="destructive"
              icon="trash_alt_solid"
              onClick={() => {
                onChange(undefined);
              }}
            >
              삭제하기
            </Button>
          </div>
        )}
      </div>
      <Modal {...modal} />
    </FormGroup>
  );
}

const OverlayVideoWrap = css`
  position: relative;
  width: 180px;
  min-width: 180px;
  height: 304px;
  margin-right: ${spacing.common.large};
  /* border-radius: ${spacing.common.small}; */

  video {
    position: relative;
    width: 153px;
    height: 203px;
    top: 57px;
    left: 14px;
    border-radius: ${spacing.common.small};
    border: 1px solid ${color.border_default_subdued};
  }

  @media (max-width: 897px) {
    ${HideElementStyle};
  }
`;

const OverlayVideo = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${assetOverlayImage.dynamicKeyVisual});
  background-position: center;
  background-size: cover;
  border-top-left-radius: ${spacing.common.small};
  border-top-right-radius: ${spacing.common.small};
`;

const VideoPreview = css`
  display: block;
  width: 160px;
  height: 213px;
  border-radius: ${spacing.borderRadius.medium};
  margin-bottom: ${spacing.margin.small};
`;
