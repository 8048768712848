import { coreApi } from '@/api';

export const MISSION_NAV_CARD_WIDTH = '160px';
export const MISSION_CARD_MAX_HEIGHT = '287px';

export function deleteHTMLTag(value: string) {
  const extractTextPattern = /(<([^>]+)>)/gi;
  return value.replace(extractTextPattern, '');
}

// eslint-disable-next-line consistent-return
export function decodeHtml(html?: string) {
  if (html) {
    const div = document.createElement('div');
    div.insertAdjacentHTML('afterbegin', html);

    return div.innerText;
  }
}

export async function createNewMission({
  appId,
  scenarioId,
  order
}: Pick<AppParam, 'appId' | 'scenarioId'> & { order?: number }) {
  const { data } = await coreApi.post<Mission>(`/apps/${appId}/scenarios/${scenarioId}/missions`, {
    name: '새 미션',
    description: '<p></p>',
    isVisibleDefault: false,
    isLockedDefault: false,
    order
  });

  return data;
}
