import { useParams, Redirect } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import {
  Button,
  ButtonProps,
  Card,
  Modal,
  spacing,
  toast
} from '@uniquegood/realworld-studio-design';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import {
  useRequest,
  useRestrictProjectRoute,
  useWrapFormToConsiderWhitespacesAsEmpty
} from '@/hooks';
import useModalState from '@/hooks/useModalState';

import { Container960 } from '@/components/containers';
import { breakpoint } from '@/styles/responsive';
import { Identity } from '@/styles';
import LoadingSpinner from '@/components/LoadingSpinner';

import { coreApi } from '@/api';

import OnBoardingModalContent, { OnBoardingStep } from '@/components/OnBoardingModalContent';
import { helpLink } from '@/utils';
import Item from './components/Item';
import Form from './components/Form';

import { steps } from './assets/images';
import { ClickEventName, PageViewEventName, track } from '@/track';

export interface FormData
  extends Omit<
    GameItem,
    'detailImageId' | 'detailImageUrl' | 'iconId' | 'iconUrl' | 'isMultiOwnable' | 'actionHandler'
  > {
  detailImage?: UserFileResponseModel;
  icon?: UserFileResponseModel;
  isSingleOwnable: boolean;
}

const formKeys: (keyof FormData)[] = [
  'description',
  'detailImage',
  'icon',
  'id',
  'isSingleOwnable',
  'name',
  'useButtonLabel'
];

type Type = Extract<keyof typeof coreApi, 'put' | 'post'>;

export default function Items() {
  const { appId, projectId } = useParams<AppParam>();
  const endpoint = `/apps/${appId}/projects/${projectId}/items`;

  const { data: items, mutate } = useRequest<GameItem[]>(endpoint);

  const redirectTo = useRestrictProjectRoute();

  const { modal, openModal, closeModal } = useModalState();

  const methods = useForm<FormData>();
  const { control, formState, handleSubmit, setValue } =
    useWrapFormToConsiderWhitespacesAsEmpty(methods);

  const typeRef = useRef<Type | null>(null);

  const onSubmit = handleSubmit(async ({ detailImage, icon, isSingleOwnable, ...omittedData }) => {
    if (!typeRef.current) throw Error('아이템을 생성과 수정 중에 무엇인지 파악하지 못했습니다.');

    const data: GameItem = {
      ...omittedData,
      isMultiOwnable: !isSingleOwnable,
      detailImageId: detailImage?.id,
      iconId: icon?.id ?? detailImage?.id
    };

    const lastPath = typeRef.current === 'put' ? `/${data.id}` : '';

    try {
      await coreApi[typeRef.current]<GameItem>(
        `/apps/${appId}/projects/${projectId}/items${lastPath}`,
        data
      );
      await mutate();

      closeModal();
      toast({ type: 'default', message: '아이템을 저장했어요' });
    } catch {
      // Empty
    }
  });

  useEffect(() => {
    track.onPageView({ pageViewEventName: PageViewEventName.view_editgame_edititem });
  }, []);

  useEffect(() => {
    if (items?.length === 0) {
      openModal({
        title: '아이템으로 게임을 더 풍성하게 만드세요!',
        size: 'big',
        children: <OnBoardingModalContent steps={onBoardingModalSteps} />,
        cssStyle: IntroModalLayout,
        primaryAction: {
          content: '아이템 만들기',
          onAction: () => {
            createItem();
            track.onClick({
              clickEventName: ClickEventName.click_editgmae_mission_item_createitem
            });
          }
        },
        secondaryAction: {
          content: '취소',
          onAction: () => {
            closeModal();
            track.onClick({
              clickEventName: ClickEventName.click_editgmae_mission_item_createitem
            });
          }
        }
      });
    }
  }, [items?.length]);

  if (items === undefined || redirectTo === undefined) return <LoadingSpinner />;

  if (typeof redirectTo === 'string') return <Redirect to={redirectTo} />;

  function handleItem(props: { type: 'post' } | { type: 'put'; defaultValues: FormData }) {
    for (const key of formKeys) {
      if (props.type === 'put' && key === 'icon') {
        const hideIconImage = props.defaultValues.icon?.id === props.defaultValues.detailImage?.id;

        setValue(key, hideIconImage ? undefined : props.defaultValues.icon);
        // eslint-disable-next-line no-continue
        continue;
      }
      setValue(key, props.type === 'put' ? props.defaultValues[key] : undefined);
    }

    typeRef.current = props.type;

    openModal({
      title: `아이템 ${typeToLocalString[props.type]}`,
      size: 'medium',
      children: <Form onSubmit={onSubmit} control={control} />,
      primaryAction: {
        content: '저장',
        icon: 'save_solid',
        htmlType: 'submit',
        form: 'modal'
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      },
      leftAction:
        props.type === 'put'
          ? {
              content: '아이템 삭제',
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onAction: () => deleteItem(props.defaultValues.id)
            }
          : undefined
    });
  }

  function deleteItem(id: string) {
    openModal({
      title: '정말 아이템을 삭제하시겠어요?',
      children:
        '아이템을 삭제하면 이미 획득한 플레이어의 인벤토리에서도 삭제되며 다시 복구할 수 없어요',
      primaryAction: {
        content: '삭제',
        icon: 'trash_alt_solid',
        type: 'destructive',
        onAction: async () => {
          try {
            await coreApi.delete(`${endpoint}/${id}`);
            mutate();

            closeModal();
            toast({ type: 'default', message: '아이템을 삭제했어요' });
          } catch {
            // Empty
          }
        }
      },
      secondaryAction: {
        content: '취소',
        onAction: closeModal
      }
    });
  }

  function createItem() {
    handleItem({ type: 'post' });
  }

  function updateItem(item: GameItem) {
    handleItem({ type: 'put', defaultValues: formatFormDefaultValues(item) });
  }

  return (
    <Container960>
      <Card>
        <ul css={ListStyle}>
          <AddItemButton
            onClick={() => {
              createItem();
              track.onClick({
                clickEventName: ClickEventName.click_editgame_mission_item_newitem
              });
            }}
            isItemEmpty={items.length === 0}
          >
            새 아이템
          </AddItemButton>
          {items.map((item) => (
            <Item key={item.id} {...item} onClick={() => updateItem(item)} />
          ))}
          {items.length < 5 && (
            <>
              {[...new Array(5 - items.length).keys()].map((key) => (
                <Item key={key} cssStyle={DummyItem} />
              ))}
            </>
          )}
        </ul>
      </Card>
      <Modal
        {...modal}
        primaryAction={{ ...modal.primaryAction, loading: formState.isSubmitting }}
      />
    </Container960>
  );
}

const typeToLocalString = { post: '생성', put: '수정' };

function formatFormDefaultValues(defaultValues: GameItem) {
  const { detailImageId, detailImageUrl, iconId, iconUrl, isMultiOwnable, ...omittedValues } =
    defaultValues;

  const detailImage =
    detailImageUrl && detailImageId
      ? {
          id: detailImageId,
          url: detailImageUrl
        }
      : undefined;

  const icon =
    iconUrl && iconId
      ? {
          id: iconId,
          url: iconUrl
        }
      : undefined;

  return {
    ...omittedValues,
    isSingleOwnable: !isMultiOwnable,
    detailImage,
    icon
  };
}

interface AddItemButtonProps extends Pick<ButtonProps, 'onClick'> {
  isItemEmpty: boolean;
}

const AddItemButton = ({
  onClick,
  isItemEmpty,
  children
}: React.PropsWithChildren<AddItemButtonProps>) => (
  <Button
    onClick={onClick}
    icon="plus_solid"
    cssStyle={[
      AddItemButtonStyle,
      isItemEmpty
        ? css`
            height: auto;
          `
        : Identity
    ]}
  >
    {children}
  </Button>
);

const AddItemButtonStyle = css`
  width: 100%;
  height: calc(100% - 4px);

  margin-bottom: ${spacing.margin.xsmall};

  border-radius: 8px;

  .Icon {
    margin: 0;
  }

  & > span {
    display: block;
    .Text {
      margin-top: 4px;
    }
  }
`;

const IntroModalLayout = css`
  figure {
    margin: 0 ${spacing.margin.xlarge2} auto;

    :first-of-type {
      margin-top: ${spacing.margin.xlarge2};
    }
    :not(:last-of-type) {
      margin-bottom: 64px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: ${spacing.margin.large};
  }
`;

const ListStyle = css`
  display: grid;
  gap: ${spacing.margin.large};

  grid-template-rows: 1fr;

  @media (min-width: ${breakpoint + 1}px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 576px) and (max-width: ${breakpoint}px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 432px) and (max-width: 575px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 432px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const onBoardingModalSteps: OnBoardingStep[] = [
  { title: '1. 게임에 활용할 아이템을 생성해보세요', imageUrl: steps.first },
  {
    title: '2. 미션 편집 페이지에서 아이템을 활용한 퀘스트를 만들어보세요',
    imageUrl: steps.second
  },
  {
    title: (
      <>
        3. 도움이 필요하다면{' '}
        <Button url={helpLink.notion.helpCenter} external>
          헬프센터
        </Button>{' '}
        혹은{' '}
        <Button url={helpLink.community} external>
          스튜디오 사용자 모임
        </Button>
        을 참고하세요
      </>
    ),
    imageUrl: steps.third
  }
];

const DummyItem = css`
  visibility: hidden;
`;
