import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import useRequest from '@/hooks/useRequest';

export default function useIsReadyToRelease() {
  const { appId, projectId } = useParams<AppParam>();
  const { data: projectData } = useRequest<CommonResponseModel<ProjectV2>>(
    `/apps/${appId}/projects/${projectId}`,
    {},
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );
  const project = projectData?.data;
  const { data: played } = useRequest<Played>(`/api/statistics/projects/${projectId}/played`);

  const quests = useQuests();

  const hasFullScenario = useMemo(() => {
    if (!quests) return undefined;

    const flatten = {
      reactionCommands: quests
        .flatMap((quest) => quest.actionHandler.reactionDefinitions)
        .flatMap(({ commands }) => commands),
      failureCommands: quests.flatMap((quest) => quest.actionHandler.failureCommands)
    };

    const merged = [...flatten.reactionCommands, ...flatten.failureCommands];

    const hasStartScenario =
      merged.findIndex(({ type }) => type === 'RealWorld.Commands.StartScenario') !== -1;

    const hasAccomplishScenario =
      merged.findIndex(
        ({ type }) =>
          type === 'RealWorld.Commands.AccomplishScenario' ||
          type === 'RealWorld.Commands.AccomplishScenario2'
      ) !== -1;

    return hasStartScenario && hasAccomplishScenario;
  }, [quests]);

  const isPlayed = played?.isStartedProject && played.isFinishedProject;

  return {
    isRequiredInfosFilled: project && isRequiredInfosFilled(project),
    hasFullScenario,
    isPlayed
  };
}

function isRequiredInfosFilled(project: ProjectV2) {
  const commonRequireFields = [
    project.name,
    project.projectType,
    project.playTimeInMinutes,
    project.difficulty !== 0,
    project.storySummary
  ];

  if (commonRequireFields.filter(Boolean).length !== commonRequireFields.length) return false;

  if (passByCommonRequireField.includes(project.projectType)) return true;

  if (requirePlayAreaGameType.includes(project.projectType)) return !!project.offlineInfo;

  throw Error('기대하지 않은 경우입니다.');
}

const passByCommonRequireField: Project['projectGameType'][] = ['Online', 'OnlineKit', 'Etc'];
const requirePlayAreaGameType: Project['projectGameType'][] = ['Offline', 'OfflineKit'];

function useQuests() {
  const { appId, projectId } = useParams<AppParam>();

  const { data: scenarios } = useRequest<Scenario[]>(
    `/apps/${appId}/projects/${projectId}/scenarios`
  );

  const scenarioId = scenarios && scenarios.length > 0 ? scenarios[0].id : undefined;

  const { data: missions } = useRequest<Mission[]>(
    `/apps/${appId}/scenarios/${scenarioId}/missions`
  );

  const quests =
    typeof scenarioId === 'number' ? missions?.flatMap((mission) => mission.quests) : undefined;

  return quests;
}
