import { Button, Modal } from '@uniquegood/realworld-studio-design';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import OnBoardingModalContent, { OnBoardingStep } from '@/components/OnBoardingModalContent';
import { useModalState, usePrevious, useRequest } from '@/hooks';
import { helpLink } from '@/utils';
import { onBoardingQuestImage } from '../assets';

export default function QuestOnboarding() {
  const { appId, scenarioId } = useParams<AppParam>();

  const { modal, openModal, closeModal } = useModalState();

  const { data: missions } = useRequest<Mission[]>(
    `/apps/${appId}/scenarios/${scenarioId}/missions`
  );
  const quests = missions?.flatMap((mission) => mission.quests);
  const previousQuests = usePrevious(quests);

  useEffect(() => {
    if (quests?.length === 1 && previousQuests?.length === 0) {
      openModal({
        size: 'big',
        title: '퀘스트의 액션과 리액션을 설정해보세요',
        children: <OnBoardingModalContent steps={questOnBoardingSteps} />,
        primaryAction: {
          content: '확인',
          onAction: closeModal
        }
      });
    }
  }, [quests]);

  return <Modal {...modal} />;
}

const questOnBoardingSteps: OnBoardingStep[] = [
  {
    title: '1. 플레이어가 수행할 액션을 선택해요',
    description: '퀴즈의 정답 입력, 비밀번호 입력, 방향 입력 등 여러가지를 할 수 있어요.',
    imageUrl: onBoardingQuestImage.step1
  },
  {
    title: '2. 플레이어가 액션을 수행했을 때 보여줄 화면을 설정해요',
    description: '실행 조건에 따라 각각 다른 화면을 보여줄 수 있어요',
    imageUrl: onBoardingQuestImage.step2
  },
  {
    title: '3. 플레이어가 오답을 입력했을 때 보여줄 화면을 설정해요',
    description: '설정한 실행 조건을 모두 충족하지 않은 경우에 오답 시 리액션이 실행되어요',
    imageUrl: onBoardingQuestImage.step3
  },
  {
    title: (
      <>
        4. 도움이 필요하다면
        <br />
        <Button url={helpLink.notion.helpCenter} external>
          헬프센터
        </Button>
        &nbsp;혹은&nbsp;
        <Button url={helpLink.community} external>
          스튜디오 사용자 모임
        </Button>
        을 참고하세요
      </>
    ),
    imageUrl: onBoardingQuestImage.help
  }
];
