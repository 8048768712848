/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { color, Icon, shadow, spacing, textStyleButton } from '@uniquegood/realworld-studio-design';
import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { onMobile } from '@styles/responsive';
import { KeyboardFocusStyle } from '@/styles';
import { ClickEventName, track } from '@/track';

import v1Image from '@/assets/images/v1_preview_for_create.png';
import v2Image from '@/assets/images/v2_preview_for_create.png';

interface ProjectItemProps {
  checkPermissionForCreateProject: (e: React.MouseEvent) => boolean;
}

function CreateProject({ checkPermissionForCreateProject }: ProjectItemProps) {
  const { appId } = useParams<AppParam>();
  const history = useHistory();

  const handleClickCreate = async (targetEngine: 'V1' | 'V2', e: React.MouseEvent) => {
    track.onClick({
      clickEventName: ClickEventName.click_channel_button_creategame,
      params: {
        engineVersion: targetEngine
      }
    });
    const isChecked = checkPermissionForCreateProject(e);

    if (!isChecked) {
      return;
    }

    if (targetEngine === 'V1') {
      history.push(`/apps/${appId}/projects/create`);
    } else if (targetEngine === 'V2') {
      history.push(`/apps/${appId}/projectsV2/create`);
    }
  };

  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const handleClickOpenModal = React.useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const handleClickCloseModal = React.useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <>
      {isOpenModal && (
        <div css={modalContainer} onClick={handleClickCloseModal}>
          <div onClick={(e) => e.stopPropagation()} css={modalWrap}>
            <div css={versionSelectTitle}>어떤 방법으로 게임 제작을 시작할까요?</div>
            <div css={versionItemContainer}>
              <div
                css={versionItem}
                onClick={(e) => {
                  handleClickCreate('V1', e);
                }}
              >
                <div css={versionImageWrap}>
                  <img src={v1Image} alt="V1 게임 미리보기" css={versionImage} />
                </div>
                <div css={versionTitleContainer}>
                  <div css={versionSubTitle}>기존 방식</div>
                  <div css={versionTitle}>미션 카드를 사용해요</div>
                </div>
              </div>
              <div css={divideContainer}>
                <div css={divideLine} />
                <div css={divideText}>또는</div>
                <div css={divideLine} />
              </div>
              <div
                css={versionItem}
                onClick={(e) => {
                  handleClickCreate('V2', e);
                }}
              >
                <div css={versionImageWrap}>
                  <img src={v2Image} alt="V2 게임 미리보기" css={versionImage} />
                </div>
                <div css={versionTitleContainer}>
                  <div css={versionSubTitle}>새로운 방식 (Beta)</div>
                  <div css={versionTitle}>플레이 플로우를 사용해요</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        type="button"
        css={[LinkTagStyle, MakeGameContainerStyle, AspectRatio, KeyboardFocusStyle]}
        onClick={() => {
          handleClickOpenModal();
        }}
      >
        <Icon icon="gamepad_solid" cssStyle={IconGamePadStyle} />
        <p css={[textStyleButton, GamePadTextStyle]}>게임 만들기</p>
      </button>
    </>
  );
}

export default CreateProject;

const modalContainer = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;

  align-items: center;
`;
const modalWrap = css`
  width: min(1100px, 90vw);
  aspect-ratio: 960 / 600;
  background-color: #f4f4f4;
  border-radius: 30px;
  padding: 32px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 960px) {
    padding: 24px;
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const LinkTagStyle = css`
  display: block;

  ${onMobile} {
    width: 100%;
    height: 140px;
  }
`;

const MakeGameContainerStyle = css`
  border-radius: ${spacing.borderRadius.xlarge};
  border: 1px solid ${color.border_default_subdued};
  background-color: ${color.surface_default_default};
  ${shadow.card};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AspectRatio = css`
  aspect-ratio: 310/340;
`;

const IconGamePadStyle = css`
  width: 150px;
  height: auto;
  margin: 0px;

  ${onMobile} {
    width: 56px;
    height: auto;
  }
`;

const GamePadTextStyle = css`
  margin: 0;
  color: ${color.text_subdued};
  margin-top: -${spacing.common.large};

  ${onMobile} {
    margin-top: 0px;
  }
`;

const versionItemContainer = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const versionItem = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
`;

const versionImageWrap = css`
  position: relative;
  padding-top: 75%;
`;

const versionImage = css`
  position: absolute;
  inset: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const divideContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const divideLine = css`
  width: 1px;
  height: 20%;
  background-color: #626262;
`;

const divideText = css`
  color: #626262;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const versionSelectTitle = css`
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 60px;

  @media (max-width: 960px) {
    font-size: 24px;
    margin-bottom: 40px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

const versionTitleContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  user-select: none;
`;

const versionSubTitle = css`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const versionTitle = css`
  color: #000;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
`;
